/**
 * Permet de créer une trappe de focus sur un élément, en empêchant la navigation hors de cet élément avec la touche Tab.
 * @param element L'élement sur lequel la trappe de focus doit être appliquée.
 * @param shouldPreventScroll Détermine si la page doit empêcher le window scroll lors de la navigation dans la trappe de focus.
 */
export function trapFocusOn(
  element: HTMLElement,
  shouldPreventScroll: boolean
) {
  const focusableElements = element.querySelectorAll(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );

  const firstFocusableElement = focusableElements[0] as HTMLElement;
  const lastFocusableElement = focusableElements[
    focusableElements.length - 1
  ] as HTMLElement;

  const focusOptions = { preventScroll: shouldPreventScroll } as FocusOptions;

  function handleFocus(e: any) {
    const isTabPressed = e.key === "Tab";

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  }

  element.addEventListener("keydown", handleFocus);

  firstFocusableElement.focus(focusOptions);
}
