import React, { Component } from "react";
import "./style.css";
import { ReactComponent as LogoDuQuebec } from "../../logos/logo_du_gouvernement.svg";

import { withTranslation } from "react-i18next";

class EnTeteDePage extends Component<any, any> {
  render() {
    const { t, i18n, activeMultilingue, activeBandeauBleu } = this.props;
    return (
      <header>
        <div className={"en-tete-de-page"}>
          <div className={"conteneur-menu"}>
            <div>
              <a href="#main-content" className="skip">
                {t("sqin_ux.header_skip_content")}
              </a>
              <a
                className={"conteneur-logo"}
                href={
                  "https://www.quebec.ca/gouvernement/faire-affaire-gouvernement/authentification-gouvernementale"
                }
              >
                <LogoDuQuebec />
              </a>
            </div>
            <div className={"conteneur-nom-service"}>
              {t("sqin_ux.header_title")}
            </div>
            <div className={"conteneur-loupe-liens"}>
              <nav aria-label={"Secondaire"}>
                <ul className={"conteneur-lien"}>
                  {activeMultilingue ? (
                    <li className={"conteneur-nous-joindre"}>
                      <a
                        href="#"
                        onClick={() => {
                          i18n.changeLanguage(
                            i18n.language === "fr" ? "en" : "fr"
                          );
                        }}
                      >
                        {i18n.language === "fr" ? "English" : "Français"}
                      </a>
                    </li>
                  ) : null}
                  <li className={"conteneur-nous-joindre"}>
                    <a href="#nous_joindre">{t("sqin_ux.header_contact_us")}</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {activeBandeauBleu && i18n.language === "en" && (
          <div className="en-tete-de-page-partie-deux">
            <div>
              {t("sqin_ux.header_section2")}
              <a
                href={t("sqin_ux.header_section2_lien")}
                className={"lien-pied-de-page"}
                target="_blank"
              >
                {t("sqin_ux.header_section2_lien_text")}
              </a>
            </div>
          </div>
        )}
      </header>
    );
  }
}

export default withTranslation(["sqin-ux"])(EnTeteDePage);
