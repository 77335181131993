import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState, ChangeEvent } from "react";
import BarreProgression from "./sqin-ux/src/Composants/BarreProgression/BarreProgression";
import Bouton from "./sqin-ux/src/Composants/Bouton/Bouton";
import { Etape } from "./sqin-ux/src/Composants/BarreProgression/Etape";
import NotificationContextuelle from "./sqin-ux/src/Composants/NotificationContextuelle/NotificationContextuelle";
import CaseACocher from "./sqin-ux/src/Composants/CaseACocher/CaseACocher";
import ChampTexte from "./sqin-ux/src/Composants/ChampTexte/ChampTexte";
import Avis from "./sqin-ux/src/Composants/Avis/Avis";
import Liste from "./sqin-ux/src/Composants/Liste/Liste";
import { Element } from "./sqin-ux/src/Composants/Liste/ListeProps";
import DialogueModal from "./sqin-ux/src/Composants/DialogueModal/DialogueModal";
import SpinnerAttente from "./sqin-ux/src/Composants/SpinnerAttente/SpinnerAttente";
import AvisCot from "./sqin-ux/src/Composants/DialogueModal/Static/Avis_Cotisation.png";
import Accueil from "./sqin-ux/src/Composants/Accueil/Accueil";
import { ReactComponent as FlecheDroite } from "./sqin-ux/src/icones/fleche-droite.svg";
import { ReactComponent as FlecheGauche } from "./sqin-ux/src/icones/fleche-gauche.svg";
import { GroupeCaseACocher } from "./sqin-ux/src/Composants/GroupeCaseACocher/GroupeCaseACocher";
import "./sqin-ux/src/Composants/NotificationContextuelle/style.css";
import FacteurDAuthentification from "./sqin-ux/src/Composants/FacteurDAuthentification/FacteurDAuthentification";
import PiedDePage from "./sqin-ux/src/Composants/PiedDePage/PiedDePage";
import EnTeteDePage from "./sqin-ux/src/Composants/EnTeteDePage/EnTeteDePage";
import Infobulle from "./sqin-ux/src/Composants/Infobulle/Infobulle";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import "./Routes.css";
import { BoutonRadio, MenuEllipse, Navigation, Tuile } from "./sqin-ux/src";
import { ChampsRadioAttr as ChampsRadioAttr } from "./sqin-ux/src/Composants/BoutonRadio/BoutonRadioProps";
import DialogueModalPros from "./sqin-ux/src/Composants/DialogueModal/DialogueModalProps";
import PasswordStrengthMeter from "./sqin-ux/src/Composants/PasswordStrengthMeter/PasswordStrengthMeter";
import aideInterrogation from "./sqin-ux/src/icones/aide.svg";
import voir from "./sqin-ux/src/icones/voir.svg";
import flechegauche from "./sqin-ux/src/icones/fleche-gauche.svg";
import ExempleMenuVertical from "./ExempleMenuVertical/ExempleMenuVertical";
import EnTeteTransactionnel from "./sqin-ux/src/Composants/EnTeteTransactionnel/EnTeteTransactionnel";
import BoutonProps from "./sqin-ux/src/Composants/Bouton/BoutonProps";
import InfobulleProps from "./sqin-ux/src/Composants/Infobulle/InfobulleProps";
import ExempleDataTable from "./ExempleDataTable/ExempleDataTable";
import { OptionMenuProps } from "./sqin-ux/src/Composants/MenuEllipse/MenuEllipseProps";
import gestionTraitement from "./sqin-ux/src/icones/gestion-des-traitements.svg";

import BoutonOnOff from "./sqin-ux/src/Composants/BoutonOnOff/BoutonOnOff";
import SectionPliable from "./sqin-ux/src/Composants/SectionPliable/SectionPliable";
import { IconList } from "./sqin-ux/src/Composants/IconList/IconList";
import Commentaire from "./sqin-ux/src/Composants/Commentaire/Commentaire";

import parseHtml from "html-react-parser";
import DOMPurify from "dompurify";
import TexteArea from "./sqin-ux/src/Composants/TexteArea/TexteArea";
import ListeDeroulante from "./sqin-ux/src/Composants/ListeDeroulante/ListeDeroulante";
import EditeurTexte from "./sqin-ux/src/Composants/EditeurTexte/EditeurTexte";
import ListeSectionCaseACocher from "./sqin-ux/src/Composants/ListeSectionCaseACocher/ListeSectionCaseACocher";
import { Option } from "./sqin-ux/src/Composants/ListeSectionCaseACocher/ListeSectionCaseACocherProps";
import CercleBleuAvecNombre from "./sqin-ux/src/Composants/CercleBleuAvecNombre/CercleBleuAvecNombre";
import { right } from "@popperjs/core";
import RadioUnique from "./sqin-ux/src/Composants/RadioUnique/RadioUnique";
import Onglets from "./sqin-ux/src/Composants/Onglet/Onglets";

const steps: Etape[] = [
  { index: 1, libelle: "Créer votre compte ", etat: "Neant" },
  { index: 2, libelle: "Valider votre courriel", etat: "Neant" },
  { index: 3, libelle: "Code de vérification", etat: "Neant" },
  { index: 4, libelle: "Conditions d'utilisation", etat: "Neant" },
];

const dialogModalChildren = (
  <div>
    <p>
      Vous êtes sur le point de signaler que vos informations <br />{" "}
      personnelles <strong>sont inexactes</strong>. Voulez-vous continuer ?
    </p>
    <h3 style={{ marginBottom: "24px" }}>Informations personnelles</h3>
    <ul className={"liste-demo"}>
      <li>
        Nom <strong>Tremblay</strong>
      </li>
      <li>
        Prénom <strong>Stéphane</strong>
      </li>
      <li>
        Date de naissance <strong>1990-08-16</strong>
      </li>
      <li>
        Numéro d'assurance sociale <strong>123 456 789</strong>
      </li>
    </ul>
    <br />
  </div>
);

const boutonsModalRadioProps: BoutonProps[] = [
  {
    type: "principal",
    children: "Fermer",
  },
];

const boutonsModalAlerteProps: BoutonProps[] = [
  {
    type: "principal",
    children: ["Oui, continuer", <FlecheDroite className={"droite"} />],
  },
  {
    type: "secondary",
    children: [<FlecheGauche className={"gauche"} />, "Annuler"],
  },
];

const boutonsModalChargementProps: BoutonProps[] = [
  {
    type: "secondary",
    children: "Annuler",
  },
];

function ouvrirAlert(e: string) {
  alert(e);
}

const dialogueModalProps: DialogueModalPros = {
  contenuLibelle: "Numéro de l’avis de cotisation de Revenu Québec",
  type: "standard",
  modalOuvert: false,
  contenuLien: <img src={aideInterrogation} alt="" className="aideLogo" />,
  boutonsProps: boutonsModalRadioProps,
};

const infoBulleProps: InfobulleProps = {
  position: "right center",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  titre: "Titre de l'infobulle",
};

const boutonsRadioattributs: ChampsRadioAttr[] = [
  {
    index: 1,
    id: "btn_rad_1",
    name: "choix_reponse",
    value: "11111",
    libelle:
      "Numéro de référence au dos de la carte d'assurance maladie (délivrée après janvier 2018)",
    texteChamps: "Exemple: 125 478 569",
    afficherAide: true,
    dialogueModalProps: dialogueModalProps,
    dialogModalContent: dialogModalChildren,
  },
  {
    index: 2,
    id: "btn_rad_2",
    name: "choix_reponse",
    value: "22222",
    libelle: "Numéro de référence à l'avant du permis de conduire",
    texteChamps: "Exemple: PBMH92V70",
    afficherAide: true,
    dialogueModalProps: dialogueModalProps,
    dialogModalContent: dialogModalChildren,
  },
  {
    index: 3,
    id: "btn_rad_2",
    name: "choix_reponse",
    value: "33333",
    libelle: "Numéro de référence à l'avant du permis de conduire",
    texteChamps: "Exemple: PBMH92V70",
    afficherAide: true,
    dialogueModalProps: dialogueModalProps,
    dialogModalContent: dialogModalChildren,
  },
  {
    index: 4,
    id: "btn_rad_2",
    name: "choix_reponse",
    value: "44444",
    libelle: "Numéro de référence à l'avant du permis de conduire",
    texteChamps: "Exemple: PBMH92V70",
    afficherAide: true,
    dialogueModalProps: dialogueModalProps,
    dialogModalContent: dialogModalChildren,
  },
];

const boutonsRadioattributs2: ChampsRadioAttr[] = [
  {
    index: 1,
    id: "btn_rad2_1",
    name: "choix_reponse2",
    value: "1",
    libelle:
      "Numéro de référence au dos de la carte d'assurance sociale (délivrée après janvier 2018)",
    texteChamps: "Exemple: 123 456 789",
    afficherAide: false,
  },
  {
    index: 2,
    id: "btn_rad2_2",
    name: "choix_reponse2",
    value: "2",
    libelle: "Numéro de référence à l'avant de votre carte de bibliothèque",
    texteChamps: "Exemple: BIBL-0987339",
    afficherAide: false,
  },
];

const boutonsRadioattributs3: ChampsRadioAttr[] = [
  {
    index: 1,
    id: "btn_rad3_1",
    name: "choix_reponse3",
    value: "1",
    libelle:
      "Numéro de référence au dos de la carte d'assurance sociale (délivrée après janvier 2018)",
    texteChamps: "Exemple: 123 456 789",
    afficherAide: false,
    disabled: true,
  },
  {
    index: 2,
    id: "btn_rad3_2",
    name: "choix_reponse3",
    value: "2",
    libelle: "Numéro de référence à l'avant de votre carte de bibliothèque",
    texteChamps: "Exemple: BIBL-0987339",
    afficherAide: false,
  },
];

const boutonsRadioattributs4: ChampsRadioAttr[] = [
  {
    index: 1,
    id: "btn_rad4_1",
    name: "choix_reponse4",
    value: "1",
    libelle:
      "No de référence au dos de la carte d'assurance sociale (délivrée après janvier 2018)",
    afficherAide: false,
  },
  {
    index: 2,
    id: "btn_rad4_2",
    name: "choix_reponse4",
    value: "2",
    libelle: "No de référence à l'avant de votre carte de bibliothèque",
    afficherAide: true,
    dialogueModalProps: dialogueModalProps,
    dialogModalContent: dialogModalChildren,
  },
];

const boutonsRadioattributs5: ChampsRadioAttr[] = [
  {
    index: 1,
    id: "btn_rad_normal",
    name: "choix_reponse4",
    value: "1",
    libelle: "Normal",
  },
  {
    index: 2,
    id: "btn_rad_important",
    name: "choix_reponse4",
    value: "2",
    libelle: "Important",
    type: "important",
  },
  {
    index: 3,
    id: "btn_rad_critique",
    name: "choix_reponse4",
    value: "3",
    libelle: "Critique",
    type: "critique",
  },
];

const barreProgressionTitre = "Inscription";
const barreProgressionProcessus = "Créer votre compte";
const message1 = "Le code a été renvoyé avec succès !";
const message2 = "Le code a été renvoyé avec succès ! la ligne 2 pour tester";
const message3 = "Ceci est un message neutre.";
const message4 = "Pour votre sécurité, votre session est terminée.";
const classname = "notification-contextuelle-positif";
const paragrapheDialogue = (
  <p>
    Isdem diebus Apollinaris Domitiani gener, paulo ante agens palatii Caesaris
    curam, ad Mesopotamiam missus a socero per militares numeros immodice
    scrutabatur, an quaedam altiora meditantis iam Galli secreta susceperint
    scripta, qui conpertis Antiochiae gestis per minorem Armeniam lapsus
    Constantinopolim petit exindeque per protectores retractus artissime
    tenebatur. Ut enim quisque sibi plurimum confidit et ut quisque maxime
    virtute et sapientia sic munitus est, ut nullo egeat suaque omnia in se ipso
    posita iudicet, ita in amicitiis expetendis colendisque maxime excellit.
    Quid enim? Minime hercule! ac ne ego quidem illius; sed ego admiratione
    quadam virtutis eius, ille vicissim opinione fortasse non nulla, quam de
    meis moribus habebat, me dilexit; auxit benevolentiam consuetudo. Sed
    quamquam utilitates multae et magnae consecutae sunt, non sunt tamen ab
    earum spe causae diligendi profectae.
  </p>
);

const titreInfobulle = "Titre de l'infobulle";
const messageInfobulle =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

const ListeErreursAvis: Element[] = [
  {
    element: (
      <a href={"#/"}>Le code permanent n'est pas valide ou n'existe pas</a>
    ),
  },
  {
    element: <a href={"#/"}>Le mot de passe n'est pas valide</a>,
  },
];

const listeDescription =
  "Une liste de base avec des marqueurs standards sous forme de points et de cercles";
const liste: Element[] = [
  {
    element: (
      <a href={"#/"}>
        Maecenas vehicula leo dui, at commodo enim ultricies id.
      </a>
    ),
    subElements: [
      "Integer sit amet diam posuere enim aliquam aliquam. Vivamus pulvinar quis quam quis sodales. Aliquam vulputate metus vel felis luctus feugiat. Duis efficitur lacinia libero eu egestas. Aenean nec ante volutpat, aliquet felis quis, auctor lorem.",
      "Vivamus pulvinar quis quam quis sodales.",
    ],
  },
  {
    element: "Maecenas vehicula leo dui, at commodo enim ultricies id",
  },
  {
    element: "Maecenas vehicula leo dui, at commodo enim ultricies id.",
    subElements: [
      "Integer sit amet diam posuere enim aliquam aliquam. Vivamus pulvinar quis quam quis sodales. Aliquam vulputate metus vel felis luctus feugiat. Duis efficitur lacinia libero eu egestas. Aenean nec ante volutpat, aliquet felis quis, auctor lorem.",
      "Vivamus pulvinar quis quam quis sodales.",
    ],
  },
];

const listeDescription2 =
  "Une liste personnalisée avec des marqueurs numériques pour les élément et les quelques sous éléments";

const liste2: Element[] = [
  {
    element: (
      <a href={"#/"}>
        Maecenas vehicula leo dui, at commodo enim ultricies id.
      </a>
    ),
    subElements: [
      "Integer sit amet diam posuere enim aliquam aliquam. Vivamus pulvinar quis quam quis sodales. Aliquam vulputate metus vel felis luctus feugiat. Duis efficitur lacinia libero eu egestas. Aenean nec ante volutpat, aliquet felis quis, auctor lorem.",
      "Vivamus pulvinar quis quam quis sodales.",
    ],
  },
  {
    element: "Maecenas vehicula leo dui, at commodo enim ultricies id",
  },
  {
    element: "Maecenas vehicula leo dui, at commodo enim ultricies id.",
    subElements: [
      "Integer sit amet diam posuere enim aliquam aliquam. Vivamus pulvinar quis quam quis sodales. Aliquam vulputate metus vel felis luctus feugiat. Duis efficitur lacinia libero eu egestas. Aenean nec ante volutpat, aliquet felis quis, auctor lorem.",
      "Vivamus pulvinar quis quam quis sodales.",
    ],
    markerStyle: "decimal",
  },
];

const Rout = () => {
  const { search } = useLocation();
  const applicationEnModeIFrame = window.location !== window.parent.location;
  const applicationEnModePleineLargeur = RouteDemandePleineLargeur();
  const styleClass = StyleClassConteneur();

  /*Notification une ligne */
  const [shown1, setShown1] = React.useState(false);
  const [shown2, setShown2] = React.useState(false);
  const [shown3, setShown3] = React.useState(false);
  const [shown4, setShown4] = React.useState(false);

  const notification1 = () => {
    setShown1(true);
  };

  const notification2 = () => {
    setShown2(true);
  };

  const notification3 = () => {
    setShown3(true);
  };

  const notification4 = () => {
    setShown4(true);
  };
  function actionClick() {
    console.log("option");
  }
  const optionsMenuEllipse: OptionMenuProps<any>[] = [
    { key: "option1", selected: false, onClick: actionClick },
    { key: "option2", selected: false, onClick: actionClick },
    { key: "option3", selected: false, onClick: actionClick },
    { key: "option4", selected: false, onClick: actionClick },
  ];

  const [textTextArea, setTextTextArea] = useState("");
  const handleChangeTextTextArea = (
    event: ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setTextTextArea(event.target.value);
  };
  const [texteEditeur, setTexteEditeur] = useState("Exemple de texte");
  const handleAnnuler = () => {
    setTexteEditeur("");
  };
  const handlePublier = () => {
    console.log("publier ce texte :" + DOMPurify.sanitize(texteEditeur));

    setTableauCommentaires([
      ...tableauCommentaires,
      DOMPurify.sanitize(texteEditeur),
    ]);
    setTexteEditeur("");
  };
  const handleChangeTexte = (htmlTexte: string) => {
    setTexteEditeur(htmlTexte);
  };
  const boutonsEditeurTexte: BoutonProps[] = [
    {
      type: "secondary",
      children: ["Annuler"],
      onClick: handleAnnuler,
    },
    {
      type: "principal",
      children: ["Publier"],
      onClick: handlePublier,
    },
  ];
  const [tableauCommentaires, setTableauCommentaires] = useState<string[]>([]);
  const options: Option[] = [
    {
      value: "option1",
      label: [<p>Adresse postale</p>, <p>300 rue mars G1R BB3</p>],
    },

    {
      value: "option2",
      label: [<p>Adresse postale</p>, <p>300 rue mars G1R BB3</p>],
    },
  ];
  const [optionChoisie, setOptionChoisie] = useState<Option | null>(null);
  return (
    <div className={styleClass}>
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route
          path="/sectionCaseACocher"
          element={
            <ListeSectionCaseACocher
              id="1"
              options={options}
              selectedOption={optionChoisie}
              onOptionSelected={setOptionChoisie}
            />
          }
        />
        <Route
          path="/avis"
          element={
            <div style={{ padding: "0 10px 0" }}>
              <Avis type="succes">
                <div>
                  <h2>Avis de succès</h2>
                  <p>Vous pouvez maintenant accéder aux services en ligne.</p>
                </div>
              </Avis>
              <br />

              <Avis type="erreur">
                <div>
                  <h2>Avis d'erreur</h2>
                  <p>
                    Vous avez entré un mot de passe incorrect à trop de
                    reprises. Votre compte est verrouillé{" "}
                    <strong>pendant 4 jours et 30 minutes.</strong>
                  </p>
                </div>
              </Avis>
              <br />
              <Avis type="erreur">
                <div>
                  <h2>Cette page contient des erreurs :</h2>
                  <Liste listElements={ListeErreursAvis} description={""} />
                </div>
              </Avis>
              <br />
              <Avis type="erreur">
                <div>
                  <p>
                    <strong>Cette page contient une erreur : </strong>
                    <a href="">
                      Le code permanent n'est pas valide ou n'existe pas.
                    </a>
                  </p>
                </div>
              </Avis>
              <br />
              <Avis type="erreur">
                <div>
                  <h2>Avis d'erreur :</h2>
                  <p>
                    Vous avez entré un mot de passe incorrect à trop de
                    reprises. Votre compte est verrouillé{" "}
                    <strong>pendant 30 minutes.</strong>
                  </p>
                  <Liste listElements={ListeErreursAvis} description={""} />
                </div>
              </Avis>
              <br />
              <Avis type="erreur">
                <div>
                  <strong>Cette page contient des erreurs :</strong>{" "}
                  <a href="#">Les informations saisies sont invalides.</a>
                  <p>
                    {" "}
                    Une ou plusieurs erreurs ont été détectées. Vérifiez toutes
                    les informations saisies et essayez à nouveau.{" "}
                  </p>
                  <p>
                    {" "}
                    Il vous reste <strong>3 tentatives</strong> avant que votre
                    compte soit temporairement suspendu.
                  </p>
                </div>
              </Avis>
              <br />
              <Avis type="important">
                <div>
                  <h2>Titre de l’avis important</h2>
                  <p>
                    Il vous reste <strong>3 tentatives</strong> de saisir le mot
                    de passe avant que votre compte soit bloqué pendant 1 heure.
                  </p>
                </div>
              </Avis>
              <br />
              <Avis type="information">
                <div>
                  <h2>Avis général</h2>
                  <p>
                    Vous êtes maintenant inscrit au Service gouvernemental
                    d’authentification.
                  </p>
                </div>
              </Avis>
              <br />
              <Avis type="information">
                <div>
                  <h3>Avis général</h3>
                  <p>Voici de l'info pour vous : </p>
                  <Liste listElements={ListeErreursAvis} description={""} />
                </div>
              </Avis>
              <br />
              <Avis type="lampe">
                <div>
                  <h2>Avis lampe</h2>
                  <p>
                    Protection et la confidentialité de vos renseignements
                    personnels{" "}
                  </p>
                </div>
              </Avis>
              <br />
              <br />
            </div>
          }
        />

        <Route
          path="/boutons"
          element={
            <div style={{ width: "100%" }}>
              <Bouton type={"secondary"}>
                <FlecheGauche className={"gauche"} />
                Précédent
              </Bouton>
              <Bouton type={"principal"}>
                Suivant
                <FlecheDroite className={"droite"} />
              </Bouton>
              <Bouton
                type={"secondary"}
                iconBoutonGauche={voir}
                iconBouton={voir}
              >
                Afficher
              </Bouton>
              <br />
              <br />
              <div style={{ textAlign: "center" }}>
                <Bouton type={"tertiary"}>Tertiaire</Bouton>
              </div>
              <br />
              <br />
              <div
                style={{
                  background: "#223654",
                  padding: "10px",
                  minWidth: "271px",
                }}
              >
                <Bouton
                  type={"secondary"}
                  reverse
                  disabled={true}
                  onClick={popup}
                >
                  <FlecheGauche className={"gauche"} />
                  Précédent
                </Bouton>

                <Bouton type={"principal"} reverse onClick={popup}>
                  Suivant
                  <FlecheDroite className={"droite"} />
                </Bouton>
                <br />
                <br />
                <div style={{ textAlign: "center" }}>
                  <Bouton type={"tertiary"} reverse>
                    Tertiaire
                  </Bouton>
                </div>
                <br />
                <br />
                <Bouton type={"secondary"} iconBoutonGauche={flechegauche}>
                  Precedent
                </Bouton>
              </div>
            </div>
          }
        />

        <Route
          path="/champs-de-texte"
          element={
            <div style={{ width: "100%" }}>
              <ChampTexte
                disabled={false}
                composantADroiteLibelle={<img src={aideInterrogation} alt="" />}
                name={"pass"}
                label={"Mot de passe"}
                type={"password"}
                format={"extra-large"}
                showpassMeter={true}
              />
              <ChampTexte
                disabled={false}
                name={"large"}
                label={"Mot de passe large"}
                type={"password"}
                format={"large"}
              />
              <ChampTexte
                disabled={false}
                name={"large"}
                label={"Large"}
                type={"text"}
                format={"large"}
              />
              <ChampTexte
                disabled={false}
                name={"extra-large"}
                label={"Extra-large"}
                type={"text"}
                format={"extra-large"}
              />
              <ChampTexte
                disabled={false}
                name={"pass"}
                label={"Moyen"}
                type={"text"}
                format={"moyen"}
                maxLength={5}
                precision={["Avec precision", "Plusieurs précisions"]}
                lien={{
                  lien: "https://itq-sqin.atlassian.net/wiki/spaces/SDD/pages/296125501/Champ+texte?NO_SSR=1",
                  label: "Voir page Confluence",
                }}
              />
              <ChampTexte
                disabled={false}
                name={"pass"}
                label={"Petit"}
                type={"text"}
                format={"petit"}
                errorMessage={"Avec Erreur"}
              />
              <ChampTexte
                disabled={false}
                name={"utilisateur"}
                label={"Nom d'utilisateur"}
                type={"text"}
                precision={["Lettres et chiffres"]}
                format={"extra-large"}
                errorMessage={["erreur1", "erreur2", "erreur3"]}
                suggestion={{
                  label: "Noms d'utilisateur disponibles:",
                  suggestions: ["Timinou123", "Timinou456", "Minou987"],
                }}
              />
              <ChampTexte
                disabled={false}
                name={"utilisateur"}
                label={"Mise en erreur manuelle"}
                type={"text"}
                precision={[
                  "Il n'y a pas de message d'erreur, mais le champ est en erreur",
                ]}
                format={"extra-large"}
                erreurManuelle={true}
              />
            </div>
          }
        />

        <Route
          path="/CaseACocher"
          element={
            <div style={{ width: "100%" }}>
              <div>
                <CaseACocher type="standard" id="1" checked>
                  Choix 1
                </CaseACocher>
                <CaseACocher type="standard" id="2">
                  Choix 2
                </CaseACocher>
                <CaseACocher type="standard" disabled id="3">
                  Choix 3
                </CaseACocher>
              </div>
              <br />
              <CaseACocher
                type="cu"
                id="4"
                checked={false}
                messageErreur={
                  "Vous devez accepter les conditions d’utilisation"
                }
              >
                <p>
                  J’ai pris connaissance et{" "}
                  <strong>j’accepte les conditions d’utilisation</strong> du
                  Service gouvernemental d’authentification.
                </p>
              </CaseACocher>

              <br />
              <CaseACocher
                type="cu"
                id="8"
                infoBulleProps={infoBulleProps}
                checked={false}
                messageErreur={
                  "Vous devez accepter les conditions d’utilisation"
                }
              >
                <p>
                  J’ai pris connaissance et{" "}
                  <strong>j’accepte les conditions d’utilisation</strong> du
                  Service gouvernemental d’authentification.
                </p>
              </CaseACocher>

              <br />
              <CaseACocher
                type="cuBlanc"
                id="5"
                messageErreur={
                  "Vous devez accepter les conditions d’utilisation"
                }
              >
                J’accepte les conditions d’utilisation du Service
                d’authentification gouvernementale
              </CaseACocher>
              <br />
              <div>
                <GroupeCaseACocher message="Faites votre choix : ">
                  <CaseACocher id="6" checked>
                    Choix 1
                  </CaseACocher>
                  <CaseACocher id="7">Choix 2</CaseACocher>
                  <CaseACocher disabled id="8">
                    Choix 3
                  </CaseACocher>
                </GroupeCaseACocher>
              </div>
            </div>
          }
        />

        <Route
          path="/dialogue-modal"
          element={
            <div>
              {paragrapheDialogue}
              <DialogueModal
                contenuLibelle="Numéro de l’avis de cotisation de Revenu Québec"
                type="standard"
                contenuLien="Ouvrir le dialogue modal"
              >
                <p>Assurez-vous que :</p>
                <ul>
                  <li>
                    Le numéro commence par Q ou M et contient 11 caractères.
                  </li>
                  <li>
                    L'avis est récent : l'une des deux dernières déclarations de
                    revenus.
                  </li>
                </ul>
                <p>
                  <a className={"lienmodal"} href="#/">
                    Comment obtenir un avis de cotisation ?
                  </a>
                </p>
                <img
                  className={"imgModal"}
                  src={AvisCot}
                  alt="Avis de cotisation"
                  width="100%"
                  height="100%"
                />
                <p>
                  Le numéro de l'avis se trouve dans la partie supérieure de
                  votre avis de cotisation
                </p>
              </DialogueModal>

              <DialogueModal
                contenuLibelle="Attention !"
                type="alerte"
                modalOuvert={false}
                contenuLien="Ouvrir le dialogue modal alerte"
                boutonsProps={boutonsModalAlerteProps}
              >
                <p>
                  Vous êtes sur le point de signaler que vos informations <br />{" "}
                  personnelles <strong>sont inexactes</strong>. Voulez-vous
                  continuer ?
                </p>
                <p style={{ marginBottom: "24px", fontWeight: "700" }}>
                  Informations personnelles
                </p>
                <ul className={"liste-demo"}>
                  <li>
                    Nom <strong>Tremblay</strong>
                  </li>
                  <li>
                    Prénom <strong>Stéphane</strong>
                  </li>
                  <li>
                    Date de naissance <strong>1990-08-16</strong>
                  </li>
                  <li>
                    Numéro d'assurance sociale <strong>123 456 789</strong>
                  </li>
                </ul>
              </DialogueModal>
            </div>
          }
        />

        <Route
          path="/modal-de-chargement"
          element={
            <div>
              <div>
                <DialogueModal
                  contenuLibelle="Vérification en cours"
                  type="alerte"
                  modalOuvert={false}
                  contenuLien="Fenêtre de chargement normal"
                  boutonsProps={boutonsModalChargementProps}
                  isSpinner={true}
                >
                  <SpinnerAttente type="Normal" vitesse={50} />

                  <p className="ligne-bas">
                    Nous procédons à la vérification de votre identité. Cela
                    devrait prendre quelques secondes. Merci de votre patience.
                  </p>
                </DialogueModal>
              </div>

              <br />
              <div>
                <DialogueModal
                  contenuLibelle="Vérification en cours"
                  type="standard"
                  modalOuvert={false}
                  contenuLien="Fenêtre de chargement ralenti"
                  boutonsProps={boutonsModalChargementProps}
                >
                  <SpinnerAttente type="Ralentissement" vitesse={25} />

                  <p className="ligne-haut">
                    Le processus de vérification prend plus de temps que prévu.
                    <br />
                    Veuillez garder la page ouverte pour quelques instants.
                  </p>
                  <br />
                  <p className="ligne-bas">
                    Nous procédons à la vérification de votre identité. Cela
                    devrait prendre quelques secondes. Merci de votre patience.
                  </p>
                </DialogueModal>
              </div>

              <br />
              <div>
                <DialogueModal
                  contenuLibelle="En attente de vérification"
                  type="standard"
                  modalOuvert={false}
                  contenuLien="Fenêtre de chargement en surcharge"
                  boutonsProps={boutonsModalChargementProps}
                >
                  <div className="ligne-message-attente">
                    Les serveurs reçoivent un grand nombre de demandes. Veuillez
                    garder la fenêtre ouverte pour conserver votre position dans
                    la liste.
                  </div>

                  <div className="mc-barre-horizontal">
                    <SpinnerAttente type="Surcharge" valeurProgression={20} />
                  </div>

                  <div className={"ligne-message-attente-bas"}>
                    <div className="mc-contenu-surcharge">
                      Nous sommes au numéro :&nbsp;
                      <div className="mc-contenu-surcharge-no">1568</div>
                    </div>
                    <div className="mc-contenu-surcharge">
                      Votre position dans la liste : &nbsp;
                      <div className="mc-contenu-surcharge-no">3345</div>
                    </div>
                  </div>
                </DialogueModal>
              </div>
            </div>
          }
        />

        <Route
          path="/infobulle"
          element={
            <div>
              <p className="infobulle_texte">
                Ceci est un exemple pour afficher l'infobulle en bas, à droite.
                <Infobulle
                  position={"bottom left"}
                  titre={titreInfobulle}
                  message={messageInfobulle}
                />
              </p>
              <p className="infobulle_texte">
                Ceci est un exemple pour afficher l'infobulle centrée en bas.
                <Infobulle
                  position={"bottom center"}
                  titre={titreInfobulle}
                  message={messageInfobulle}
                />
              </p>
              <p className="infobulle_texte">
                Ceci est un exemple pour afficher l'infobulle en bas, à gauche.
                <Infobulle
                  position={"bottom right"}
                  titre={titreInfobulle}
                  message={messageInfobulle}
                />
              </p>
              <hr />
              <p className="infobulle_texte">
                Ceci est un exemple pour afficher l'infobulle au centre, à
                gauche.
                <Infobulle
                  position={"left center"}
                  titre={titreInfobulle}
                  message={messageInfobulle}
                />
              </p>
              <hr />
              <p className="infobulle_texte">
                Ceci est un exemple pour afficher l'infobulle au centre, à
                droite.
                <Infobulle
                  position={"right center"}
                  titre={titreInfobulle}
                  message={messageInfobulle}
                />
              </p>
              <hr />
              <p className="infobulle_texte">
                Ceci est un exemple pour afficher l'infobulle au top à droite.
                <Infobulle
                  position={"top left"}
                  titre={titreInfobulle}
                  message={messageInfobulle}
                />
              </p>
              <p className="infobulle_texte">
                Ceci est un exemple pour afficher l'infobulle centrée au top.
                <Infobulle
                  position={"top center"}
                  titre={titreInfobulle}
                  message={messageInfobulle}
                />
              </p>
              <p className="infobulle_texte">
                Ceci est un exemple pour afficher l'infobulle au top à gauche.
                <Infobulle
                  position={"top right"}
                  titre={titreInfobulle}
                  message={messageInfobulle}
                />
              </p>
            </div>
          }
        />

        <Route
          path="/liste"
          element={
            <div>
              <Liste listElements={liste} description={listeDescription} />
              <br />
              <Liste
                listElements={liste2}
                description={listeDescription2}
                markerStyle={"decimal"}
              />
            </div>
          }
        />

        <Route
          path="/notification"
          element={
            <>
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    id="lien"
                    className="lien-notification"
                    onClick={notification1}
                  >
                    Afficher notification (1 ligne)
                  </button>

                  <button className="lien-notification" onClick={notification2}>
                    Afficher notification (2 ligne)
                  </button>

                  <button className="lien-notification" onClick={notification3}>
                    Afficher notification neutre
                  </button>

                  <button className="lien-notification" onClick={notification4}>
                    Afficher notification négative
                  </button>
                </div>
                <div className="notifications-conteneur">
                  <NotificationContextuelle
                    message={message1}
                    etat={"positif"}
                    classname={classname}
                    onClick={notification1}
                  />

                  <NotificationContextuelle
                    message={message2}
                    etat={"positif"}
                    classname={classname}
                    onClick={notification2}
                  />

                  <NotificationContextuelle
                    message={message3}
                    etat={"neutre"}
                    classname={classname}
                    onClick={notification3}
                  />

                  <NotificationContextuelle
                    message={message4}
                    etat={"negatif"}
                    classname={classname}
                    onClick={notification4}
                  />
                </div>
              </div>
            </>
          }
        />

        <Route
          path="/progress-bar"
          element={
            <BarreProgression
              etapes={steps}
              titre={barreProgressionTitre}
              processus={barreProgressionProcessus}
            />
          }
        />

        <Route
          path="/facteur-d-authentification"
          element={
            <div className={"facteur-home"}>
              <FacteurDAuthentification
                type={"Code"}
                content={"123 456"}
                titre={"Code de sécurité :"}
              />
              <br />
              <br />
              <FacteurDAuthentification
                type={"Inscription"}
                content={
                  "abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstu@gmail.com"
                }
                titre={"Un code sera envoyé à l'adresse :"}
              />
              <br />
              <br />
              <FacteurDAuthentification
                type={"Connexion"}
                content={"timinou123@gmail.com"}
                titre={"Un code sera envoyé à l'adresse :"}
              />
              <br />
              <br />
              <FacteurDAuthentification
                type={"Recuperation"}
                content={"timinou123@gmail.com"}
                titre={"Un mot de passe temporaire a été envoyé à l'adresse :"}
              />
              <br />
              <br />
            </div>
          }
        />

        <Route path="/pied-de-page" element={<PiedDePage />} />
        <Route
          path="/en-tete-de-page"
          element={
            <div className="containerEntete">
              <div className="entete">
                <EnTeteDePage activeMultilingue activeBandeauBleu />
              </div>
              <div className="piedEntete">
                <PiedDePage />
              </div>
            </div>
          }
        />

        <Route
          path="/password-strength-meter"
          element={
            <div className="pwd-strength-meter">
              <br />
              <div className="pwd-strength-meter-short">
                <PasswordStrengthMeter password="yoRdFuq@13dd9#EDd" />
              </div>
              <br />
              <div className="pwd-strength-meter-medium">
                <PasswordStrengthMeter password="yoRdFuq" />
              </div>
              <br />
              <div className="pwd-strength-meter-long">
                <PasswordStrengthMeter password="yoR3d9#EDd" />
              </div>
              <br />
              <div className="pwd-strength-meter-xlong">
                <PasswordStrengthMeter password="1-2928yoR3d9#EDd" />
              </div>
            </div>
          }
        />

        <Route
          path="/en-tete-transactionnel"
          element={
            <EnTeteTransactionnel>
              <Bouton type={"secondary"} reverse>
                Se déconnecter
              </Bouton>
              <Bouton type={"principal"} reverse>
                Gérer votre compte
              </Bouton>
            </EnTeteTransactionnel>
          }
        />

        <Route
          path="/bouton-radio"
          element={
            <div style={{ width: "100%" }}>
              <form>
                <BoutonRadio
                  name="grOpt1"
                  titre={"Saisir l'une des informations suivantes"}
                  listeChampsRadio={boutonsRadioattributs}
                  dialogueModalPrincipalProps={dialogueModalProps}
                  type="champsTexte"
                  dialogModalPrincipalContent={dialogModalChildren}
                  afficherAide={true}
                  onChange={ouvrirAlert}
                  selected={0}
                />
                <div
                  style={{
                    borderTop: "2px solid #c0c0c0 ",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                />
                <BoutonRadio
                  name="grOpt2"
                  titre={"Ou saisir une des informations suivantes"}
                  listeChampsRadio={boutonsRadioattributs2}
                  type="champsTexte"
                  afficherAide={false}
                  selected={1}
                />
                <div
                  style={{
                    borderTop: "2px solid #c0c0c0 ",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                />
                <BoutonRadio
                  name="grOpt3"
                  titre={"Ou saisir une des informations suivantes"}
                  listeChampsRadio={boutonsRadioattributs3}
                  type="champsTexte"
                  dialogueModalPrincipalProps={dialogueModalProps}
                  dialogModalPrincipalContent={dialogModalChildren}
                  afficherAide={true}
                />
                <div
                  style={{
                    borderTop: "2px solid #c0c0c0 ",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                />
                <br />
                <BoutonRadio
                  name="grOpt4"
                  titre={"Ou saisir une des informations suivantes"}
                  listeChampsRadio={boutonsRadioattributs4}
                  type="simple"
                  afficherAide={false}
                />
                <div
                  style={{
                    borderTop: "2px solid #c0c0c0 ",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                />
                <br />
                <BoutonRadio
                  name="grOpt5"
                  titre={"Priorité"}
                  listeChampsRadio={boutonsRadioattributs5}
                  type="simple"
                />
              </form>
            </div>
          }
        />
        <Route path="/menu-vertical/*" element={<ExempleMenuVertical />} />
        <Route path="/carte" element={<ExempleMenuVertical />} />
        <Route path="/data-table" element={<ExempleDataTable />} />
        <Route
          path="/menu-ellipse"
          element={<MenuEllipse listeOptions={optionsMenuEllipse} />}
        />

        <Route
          path="/bouton-on-off"
          element={
            <div style={{ padding: "0 30px 0" }}>
              <BoutonOnOff onChange={(e) => console.log(e)} />
            </div>
          }
        />
        <Route
          path="/Tuile"
          element={
            <div style={{ padding: "0 30px 0" }}>
              <Tuile
                title={"Gestion des traitements"}
                icon={gestionTraitement}
                paragraph={
                  "Courte description du service qui tiendra sur 2-3 lignes. Lorem ipsum dolor set amet."
                }
                lien="#"
              />{" "}
            </div>
          }
        />
        <Route
          path="/Navigation"
          element={
            <div style={{ padding: "0 30px 0" }}>
              <Navigation paragraph={"Retour à la page d'accueil"} lien="#" />{" "}
            </div>
          }
        />
        <Route
          path="/Collapsable"
          element={
            <div
              style={{
                width: "50%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <SectionPliable
                  titre={"Fermeture de compte"}
                  sousTitre={"fermeture de compte par à l'inactivité"}
                  ouverte={false}
                >
                  <div>
                    What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to
                    make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the
                    1960s with the release of Letraset sheets containing Lorem
                    Ipsum passages, and more recently with desktop publishing
                    software like Aldus PageMaker including versions of Lorem
                    Ipsum
                  </div>
                </SectionPliable>
              </div>
              <div style={{ marginTop: "20px" }}>
                <SectionPliable
                  titre={"Fermeture de compte"}
                  sousTitre={"fermeture de compte par à l'inactivité"}
                  maskConfig={{
                    labelMasqueActif: "Afficher",
                    labelMasqueInactif: "Cacher",
                  }}
                  ouverte={false}
                >
                  <div>
                    <AdressForm />
                  </div>
                </SectionPliable>
              </div>
            </div>
          }
        />
        <Route path="/Icons" element={<IconList />} />
        <Route
          path="/bloc-commentaire"
          element={
            <div>
              <Commentaire
                commentateur={"User007"}
                datePublication={"2024-03-12"}
                texte={"Lorem ipsum dolor sit amet,"}
              />
              <Commentaire
                commentateur={"Nom Prenom"}
                datePublication={"2024-03-11"}
                texte={
                  "Arrivera cailloux ces defoncat cornette les regardez ifs activite."
                }
              />
              <Commentaire
                commentateur={"Utilisateur01"}
                datePublication={"2024-03-11"}
                texte={
                  " Principle september she conveying did eat may extensive. Principle september she conveying did eat may extensive. Principle september she conveying did eat may extensive. Principle september she conveying did eat may extensive."
                }
              />
            </div>
          }
        />
        <Route
          path="/texte-area"
          element={
            <div>
              <TexteArea
                maxLength={2000}
                value={textTextArea}
                onChange={handleChangeTextTextArea}
              />
              <TexteArea />
            </div>
          }
        />
        <Route
          path="/editeur-texte"
          element={
            <div>
              <EditeurTexte
                placeholder="Placeholder facultatif"
                htmlTexte={texteEditeur}
                buttons={boutonsEditeurTexte}
                onChange={handleChangeTexte}
              />
              <h3>texte original :</h3>
              <p>{texteEditeur}</p>
              <h3>résultat :</h3>
              {parseHtml(DOMPurify.sanitize(texteEditeur))}
              {tableauCommentaires.map((value) => {
                return (
                  <Commentaire
                    texte={value}
                    commentateur="utilisateur"
                    datePublication={new Date().toLocaleDateString()}
                  />
                );
              })}
            </div>
          }
        />
        <Route
          path="/liste-deroulante"
          element={
            <div style={{ margin: "50px" }}>
              <div style={{ marginBottom: "50px" }}>
                <ListeDeroulante
                  options={[
                    { label: "Label 1", value: 1 },
                    { label: "Label 2", value: 2 },
                    { label: "Label 3", value: 3 },
                    { label: "Label 4", value: 4 },
                    { label: "Label 5", value: 5 },
                    { label: "Label 6", value: 6 },
                    { label: "Label 7", value: 7 },
                    { label: "Label 8", value: 8 },
                    { label: "Label 9", value: 9 },
                    { label: "Label 10", value: 10 },
                    { label: "Label 11", value: 11 },
                    { label: "Label 12", value: 12 },
                  ]}
                  placeholder="Selectionner..."
                  ariaLabel="option de la demande"
                  ariaDescribed="choisir l'option de la demande"
                  customStyles={{
                    dropdownIndicator: (base: any) => ({
                      ...base,
                      color: "#095797",
                    }),
                  }}
                  onChange={(option: { label: string; value: any }) =>
                    console.log(JSON.stringify(option))
                  }
                />
              </div>
              <div style={{ marginBottom: "30px" }}>
                <ListeDeroulante
                  options={[
                    { label: "Label 1", value: 1 },
                    { label: "Label 2", value: 2 },
                    { label: "Label 3", value: 3 },
                  ]}
                  errorMessage="Ce champ est requis"
                  ariaLabel="option de la demande"
                  ariaDescribed="choisir l'option de la demande"
                  onChange={(option: { label: string; value: any }) =>
                    console.log(JSON.stringify(option))
                  }
                />
              </div>
              <div>
                <ListeDeroulante
                  options={[
                    { label: "Label 1", value: 1 },
                    { label: "Label 2", value: 2 },
                    { label: "Label 3", value: 3 },
                  ]}
                  ariaLabel="option de la demande"
                  ariaDescribed="choisir l'option de la demande"
                  disabled
                  value={1}
                  onChange={(option: { label: string; value: any }) =>
                    console.log(JSON.stringify(option))
                  }
                />
              </div>
            </div>
          }
        />
        <Route
          path="/radio-unique"
          element={
            <div style={{ margin: "50px" }}>
              <div>
                <RadioUnique
                  label={"Radio non selectionnée"}
                  style={{ display: "flex", marginBottom: "15px" }}
                  checked={false}
                  onChange={() => null}
                />
                <RadioUnique
                  label={"Radio  selectionnée"}
                  style={{ display: "flex" }}
                  checked={true}
                  onChange={() => null}
                />
              </div>
            </div>
          }
        />
        <Route
          path="/cercle-bleu-nombre"
          element={
            <div>
              <div style={{ display: "flex" }}>
                <CercleBleuAvecNombre texte={1} />
                &nbsp;Cercle bleu avec chiffre 1{" "}
              </div>
            </div>
          }
        />
        <Route
          path="/onglet"
          element={
            <div>
              <Onglets
                elements={[
                  { titre: "Tab-1", children: <div> Contenue la Tab1</div> },
                  { titre: "Tab-2", children: <div> Contenue la Tab2</div> },
                ]}
              />
            </div>
          }
        />
      </Routes>
    </div>
  );

  function popup() {
    alert("Activé!");
  }

  function RouteDemandePleineLargeur(): boolean {
    const queryString = search;
    let pleineLargeur = false;
    if (!isEmpty(queryString)) {
      const params = new URLSearchParams(queryString);
      const paramPleineLargeur = params.get("pleine-largeur");
      if (paramPleineLargeur) {
        pleineLargeur = /true/i.test(paramPleineLargeur);
      }
    }
    return pleineLargeur;
  }

  function StyleClassConteneur(): string {
    let styleClass: string;
    if (applicationEnModeIFrame) {
      styleClass = "app-frame";
    } else if (applicationEnModePleineLargeur) {
      styleClass = "App--pleine-largeur";
    } else {
      styleClass = "App";
    }
    return styleClass;
  }
};

export default Rout;

const AdressForm = (props: any) => {
  const [user, setUser] = useState({
    isLoading: true,
    nom: "",
    prenom: "",
    adresse: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setUser({
        nom: "John",
        prenom: "Doe",
        adresse: "1444 John Doe Street BC, CA",
        isLoading: false,
      });
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (user.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>Nom: {user.nom}</div>
      <div>Prenom: {user.prenom}</div>
      <div>Adresse: {user.adresse}</div>
    </div>
  );
};
