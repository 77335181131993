import SectionCaseACocherProps from "./SectionCaseACocherProps";
import "./style.css";

const SectionCaseACocher = (props: SectionCaseACocherProps) => {
  function handleChange(e: any) {
    if (props.onChange) {
      props.onChange(e);
    }
  }
  const style = ["avis-format"];

  const id = props.id ? props.id : props.children?.toString();
  const name = props.name ? props.name : "case" + id;

  return (
    <div className={`avis-format ${props.checked ? "blueBorder" : " "} `}>
      <div className="avis-zonecoloree-information">
        <input
          type="radio"
          className="checkbox-round"
          id={id}
          name={name}
          value={props.value ? props.value : "on"}
          disabled={props.disabled}
          checked={props.checked}
          onChange={handleChange}
        />
      </div>
      <div className="contenuAvis">{props.children}</div>
    </div>
  );
};

export default SectionCaseACocher;
