import React, { useState, useEffect } from "react";

import InfobulleProps from "./InfobulleProps";
import { Popup } from "semantic-ui-react";
import "./style.css";
import "../../style.css";

import fermerInfobulle from "../../icones/x-fermer-modal.svg";
import aideInfobulle from "../../icones/infobulle-aide-2.svg";
import { useTranslation } from "react-i18next";
import uniqueId from "lodash/uniqueId";
import { trapFocusOn } from "../../utils";

const Infobulle = (props: InfobulleProps) => {
  const { t, i18n } = useTranslation(["sqin-ux"]);
  const [ouvert, definirOuvert] = useState(false);
  const [isDesktop, setDesktop] = useState(false);

  const contextRef = React.useRef<any | null>();
  const popupBtnDesktopId = uniqueId("popupBtnDesktop-") as string;
  const popupBtnMobileId = uniqueId("popupBtnMobile-") as string;
  const popupDesktopId = uniqueId("popupDesktop-") as string;
  const popupMobileId = uniqueId("popupMobile-") as string;

  function setWidth() {
    const popupMobile = document.getElementById(popupMobileId) as HTMLElement;
    const largeurPage = popupMobile?.parentElement?.parentElement?.clientWidth;
    if (popupMobile && largeurPage) {
      popupMobile.style.width = largeurPage + "px";
    }
  }

  useEffect(() => {
    if (!isDesktop && ouvert) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    if (ouvert) {
      const popupDesktop = document.getElementById(
        popupDesktopId
      ) as HTMLElement;
      const popupMobile = document.getElementById(popupMobileId) as HTMLElement;

      if (popupDesktop) trapFocusOn(popupDesktop, true);

      if (popupMobile) trapFocusOn(popupMobile, true);
    }
  }, [ouvert, isDesktop]);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }

    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const updateMedia = () => {
    if (window.innerWidth > 590) {
      setDesktop(true);
    } else {
      setDesktop(false);
      setWidth();
    }
  };

  const fermerModal = (popupBtnId: string) => {
    definirOuvert(false);
    const popupBtn = document.getElementById(popupBtnId) as HTMLElement;

    if (popupBtn) popupBtn.focus();
  };

  const getAccessibiliteDescription = (): string => {
    if (props.accessibiliteDescription) {
      if (i18n.exists(props.accessibiliteDescription)) {
        return t(props.accessibiliteDescription);
      }
      return props.accessibiliteDescription;
    }

    return t("sqin_ux.infobulle.accessibilite.info");
  };

  return (
    <div className="conteneur-aide">
      {isDesktop ? (
        <Popup
          id={popupDesktopId}
          on="click"
          open={ouvert}
          onOpen={() => definirOuvert(true)}
          onClose={() => definirOuvert(false)}
          pinned
          position={props.position ?? "right center"}
          className={"popup_desktop"}
          trigger={
            <button id={popupBtnDesktopId} className="info-lien">
              <img src={aideInfobulle} alt={getAccessibiliteDescription()} />
            </button>
          }
        >
          <button
            className="imgFermetureTT"
            onClick={() => fermerModal(popupBtnDesktopId)}
          >
            <img
              src={fermerInfobulle}
              alt={t("sqin_ux.infobulle.accessibilite.fermer")}
            />
          </button>
          <div className="inside_tooltip">
            <strong>{props.titre}</strong>
            <br />
            {props.message}
          </div>
        </Popup>
      ) : (
        <>
          <Popup
            id={popupMobileId}
            on="click"
            open={ouvert}
            onOpen={() => definirOuvert(true)}
            onClose={() => definirOuvert(false)}
            context={contextRef}
            size={"huge"}
            wide={"very"}
            className={"popup_mobile"}
            trigger={
              <button id={popupBtnMobileId} className="info-lien">
                <img src={aideInfobulle} alt="" />
              </button>
            }
          >
            <button
              className="imgFermetureTT"
              onClick={() => fermerModal(popupBtnMobileId)}
            >
              <img
                src={fermerInfobulle}
                alt={t("sqin_ux.infobulle.accessibilite.fermer")}
              />
            </button>
            <div className="inside_tooltip_mobile_conteneur">
              <strong className="inside_tooltip_mobile">{props.titre}</strong>
              <br />
              <div className="inside_tooltip_mobile">{props.message}</div>
            </div>
          </Popup>
          <div ref={contextRef} className={"infobulle_mobile"} />
        </>
      )}
    </div>
  );
};

export default Infobulle;
